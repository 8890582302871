<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="quan">
        <heade></heade>
        <div class="con">
            <div class="box">
                <ul class="left">
                    <li v-for="(i,index) in list" :key="index" @click="dianji_tiaozhuan(i)" :class="i.path == path?'bei':''">{{i.name}}</li>
                </ul>
                <div class="right">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <foote></foote>
    </div>
</template>

<script>
import heade from '../Heade'
import foote from '../Foote'
import { query_user_info, query_ent_sub_manager_list } from '../../api/api'
export default {
  name: 'quan',
  data () {
    return {
      list: [],
      // {
      //         name:'目标',
      //         lujing:'/enttarget'
      //     },
      //     {
      //         name:'销售',
      //         lujing:'/entsale'
      //     },
      //     {
      //         name:'产品',
      //         lujing:''
      //     },
      //     {
      //         name:'核算',
      //         lujing:''
      //     },
      //     {
      //         name:'风控',
      //         lujing:''
      //     },
      //     {
      //         name:'成长',
      //         lujing:''
      //     },
      path: ''
    }
  },
  components: { heade, foote },
  created () {
    this.lujing(this.$route.path)
    this.jichu()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      deep: true
    }
  },
  methods: {
    lujing (path) {
      let pat = ''
      if (path == '/enttarsale' || path == '/enttarproduct' || path == '/enttarcount') {
        pat = '/enttarget'
      } else {
        pat = path
      }
      this.path = pat
    },
    // 点击跳转
    dianji_tiaozhuan (i) {
      this.$router.push(i.path)
    },
    jichu () {
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1) {
            this.list = [
              {
                name: '目标',
                path: '/enttarget'
              },
              {
                name: '销售',
                path: '/entsale'
              },
              {
                name: '产品',
                path: '/prodjur'
              },
              {
                name: '全模块',
                path: '/modelall'
              }
              // {
              //     name:'全模块-销售',
              //     path:'/modelallsale'
              // },
            ]
          } else {
            query_ent_sub_manager_list({
              data: {
                ent_id: this.$ent_id()
              }
            }).then(rbs => {
              console.log(rbs)
              if (rbs.data.code == 200) {
                const data = JSON.parse(rbs.data.body.data)
                console.log(data)
                let date = ''
                data.forEach(item => {
                  if (item.staff_id == this.$jichuxinxi().user_id) {
                    date = item
                  }
                })
                console.log(date)
                const mod_list = date.module_id.split(',')
                let mubiao = false
                let xiaoshou = false
                const chanpin = false
                mod_list.forEach(item => {
                  if (item == '1-3' || item == '1-4' || item == '1-2') {
                    mubiao = true
                  }
                  if (item == '2') {
                    xiaoshou = true
                  }
                  if (item == '3') {
                    xiaoshou = true
                  }
                })
                const zhanshi_list = []
                if (mubiao) {
                  zhanshi_list.push({
                    name: '目标',
                    path: '/enttarget'
                  })
                }
                if (xiaoshou) {
                  zhanshi_list.push({
                    name: '销售',
                    path: '/entsale'
                  })
                }
                if (chanpin) {
                  zhanshi_list.push({
                    name: '产品',
                    path: '/prodjur'
                  })
                }
                this.list = zhanshi_list
              }
            })
          }
        }
      })
    }
    //   dian_list(index){
    //       this.inde=index
    //         if(this.inde==0){
    //             this.$router.push('/target')
    //         }else if(this.inde==1){
    //             this.$router.push('/sales')
    //         // }else if(this.inde==2){
    //         //     this.$router.push('/')
    //         // }else if(this.inde==3){
    //         //     this.$router.push('/')
    //         }
    //         sessionStorage.setItem("pc_pc_pc_fenxi", JSON.stringify(this.inde));
    //     }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.quan {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        width: 100%;
        background:#E7E8EB;
        display: flex;
        padding-top:1.8rem;
        padding-bottom: 0.46rem;
        .box{
            width:12rem;
            min-height:6rem;
            margin:0 auto;
            background: #fff;
            display: flex;
            .left{
                width: 2.2rem;
                height:100%;
                float: left;
                background-color: #F4F6F8;
                li{
                    height:0.6rem;
                    width: 100%;
                    font-size: 0.18rem;
                    color: #888888;
                    text-align: center;
                    line-height: 0.6rem;
                    cursor:pointer;
                    font-size: 0.16rem;
                }
                .bei{
                    background: #979AA1;
                    color: #fff;
                }
            }
            .right{
                float: left;
                width:9.8rem;
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
      }
    }
}
</style>
